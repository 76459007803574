<template>
  <div id="NotFoundPage">
    <section class="hero is-primary is-fullheight">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-1">PAGE NOT FOUND</h1>
          <h2 class="subtitle is-5">
            Maybe you input a wrong URL. Please input it again :)
          </h2>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage"
};
</script>

<style></style>
